<!-- =========================================================================================
  File Name: LabCard.vue
  Description: Item Component - Grid VIew

========================================================================================== -->

<template>
  <vx-card class="grid-view-item mb-base overflow-hidden w-full no-padding border-0">
    <template slot="no-body">
      <div class="simulation-icon-top-right">
        <feather-icon
          v-if="item.percent === 100 || item.completed"
          icon="CheckCircleIcon"
          svgClasses="h-8 w-8 self-center bg-success rounded-full p-1 text-white"
        />
        <feather-icon v-else icon="XCircleIcon" svgClasses="h-8 w-8 self-center bg-danger rounded-full p-1 text-white" />
      </div>

      <vs-progress :percent="item.percent" class="block shadow-md bg-danger" color="success" v-if="!item.completed"></vs-progress>
      <vs-progress :percent="100" class="block shadow-md bg-danger" color="success" v-if="item.completed"></vs-progress>
      <!-- <vs-progress :percent="100" class="block shadow-md bg-danger" color="danger" v-if="!item.completed && item.percent === 0"></vs-progress> -->

      <div class="item-img-container flex items-center justify-center cursor-pointer" @click="starterActive = true">
        <img :src="item.thumbnail" :alt="item.name" class="grid-view-img" style="width: 100%; height: 300px; object-fit: cover" />
        <div class="simulation-card-overlay flex flex-col justify-between">
          <div class="flex">
            <img
              src="https://connect-cdn.intellectualpoint.com/assets/images/logos/IntellectualPointIcon.png"
              class="w-8 self-center"
              alt=""
            />
          </div>
        </div>
      </div>

      <vs-popup :active.sync="starterActive" style-content="max-height: 600px" :title="`Practice Simulation`">
        <div class="p-3">
          <div class="flex items-center justify-between mb-3">
            <div class="flex items-center">
              <img :alt="item.name" v-if="item.thumbnail" :src="item.thumbnail" width="75" class="rounded-lg mr-4" />
              <div>
                <h1 class="font-bold">{{ item.name }}</h1>
                <p class="font-light">{{ activeCourseInfo.name }}</p>
              </div>
            </div>
            <radial-progress-bar
              v-if="!item.completed"
              :diameter="90"
              :completed-steps="item.percent"
              :total-steps="100"
              :animateSpeed="1100"
              timingFunc="ease"
              innerStrokeColor="#181d2a"
              startColor="#31B952"
              stopColor="#0cdc62"
              :strokeWidth="7"
              :innerStrokeWidth="7"
            >
              <h3 class="font-light">{{ item.percent }}%</h3>
            </radial-progress-bar>
            <radial-progress-bar
              v-else
              :diameter="90"
              :completed-steps="100"
              :total-steps="100"
              :animateSpeed="1100"
              timingFunc="ease"
              innerStrokeColor="#181d2a"
              startColor="#31B952"
              stopColor="#0cdc62"
              :strokeWidth="7"
              :innerStrokeWidth="7"
            >
              <h3 class="font-light">100%</h3>
            </radial-progress-bar>
          </div>

          <vs-button class="w-full font-bold mb-4" icon="icon-play" icon-pack="feather" @click="startSimulation"
            >Start Simulation
          </vs-button>

          <p class="font-semibold mb-1">Description</p>
          <div class="p-3 rounded-lg mb-4" style="background: rgba(0, 0, 0, 0.35)">
            <div class="instructions-show-details" v-html="item.instructions"></div>
          </div>

          <div class="mb-4" v-if="item.explanation_video_id">
            <p class="font-semibold mb-1">Explanation Video</p>
            <hls-player v-if="starterActive" :video-id="item.explanation_video_id" :key-id="item.id" player-class="rounded-lg" />
          </div>

          <p class="font-semibold mb-1">Progress</p>
          <div class="p-3 rounded-lg mb-4" style="background: rgba(0, 0, 0, 0.35)">
            <span class="font-bold" v-if="item.percent !== 100">You are currently at {{ item.percent }}% complete.</span>
            <span class="font-bold" v-else>You have completed the simulation!</span>
          </div>
        </div>
      </vs-popup>
    </template>
  </vx-card>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';
import HlsPlayer from '../../../../../components/plyr/HlsPlayer';

export default {
  data() {
    return {
      starterActive: false,
    };
  },
  methods: {
    startSimulation() {
      this.starterActive = false;
      setTimeout(() => {
        this.$router.push(`/simulations/${this.item.id}/play`);
      });
    },
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    RadialProgressBar,
    HlsPlayer,
  },
};
</script>

<style lang="scss">
.instructions-show-details {
  img {
    max-width: -webkit-fill-available;
  }
}

.grid-view-item {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  .vs-progress--background {
    z-index: 0 !important;
  }

  .simulation-card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.25rem;
  }

  .simulation-icon-top-right {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 1;
  }
}
</style>
