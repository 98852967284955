<!-- =========================================================================================
  File Name: SimulationSelector.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div
      class="rounded-lg justify-between items-center rounded-lg mb-8"
      style="
        background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
        box-shadow: rgb(0 0 0 / 10%) 0px 4px 25px 0px;
        background-size: cover;
      "
    >
      <div class="flex justify-between items-center rounded-lg p-4" id="simulation-header" style="border: 1px solid #181d2a">
        <div class="flex items-center">
          <img
            alt="simulations"
            draggable="false"
            src="https://connect-cdn.intellectualpoint.com/assets/images/apps/SimulationsIcon.png"
            class="rounded hidden sm:block mr-5"
            style="width: 75px"
          />
          <div>
            <h1 class="font-bold">Simulation Selector</h1>
            <h5 class="font-light">{{ simulations.length }} Total Simulations</h5>
          </div>
        </div>

        <div class="flex flex-col items-end justify-center" id="simulation-search">
          <vs-input
            v-model="simulationSearch"
            placeholder="Search For Simulation"
            @input="filterSimulations"
            class="w-full"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            icon-no-border
          ></vs-input>
        </div>
      </div>
    </div>

    <div v-if="simulationsLoaded">
      <div class="simulations-grid-view vx-row match-height">
        <div v-for="item in simulations" :key="item.id" class="vx-col card-width-sims">
          <simulation-card :item="item" />
        </div>
      </div>
      <h1 v-if="!simulations.length">No Simulations Found</h1>
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
  </div>
</template>

<script>
import SimulationCard from './components/SimulationCard';
import LottieAnimation from '../../../../components/lottie/LottieAnimation';

export default {
  components: {
    SimulationCard,
    LottieAnimation,
  },
  data() {
    return {
      simulations: [],
      simulationsTotal: [],
      simulationsLoaded: false,

      simulationSearch: '',
    };
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  methods: {
    filterSimulations() {
      this.simulations = this.simulationsTotal.filter((simulation) => {
        return simulation.name.toLowerCase().includes(this.simulationSearch.toLowerCase().trim());
      });
    },
    loadSimulationsData() {
      this.simulationsLoaded = false;

      if (this.activeCourseInfo.selected === false) {
        this.$vs.notify({
          title: 'No Course Selected',
          text: 'Please select a course before practicing',
          color: 'danger',
          position: 'top-right',
        });

        this.$router.push('/courses');

        return;
      }

      this.$http
        .get(`simulation/selector?courseId=${this.activeCourseInfo.id}`)
        .then((response) => {
          if (response.data) {
            if (response.data.simulations.length === 0) {
              this.$vs.notify({
                title: 'No Simulations In Course',
                text: 'There are no simulations in the course you are trying to practice',
                color: 'danger',
                position: 'top-right',
              });
              // return this.$router.push('/apps');
            }

            this.simulationsTotal = response.data.simulations;
            this.simulations = this.simulationsTotal;
            this.simulationsLoaded = true;
          }
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while loading simulations';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed To Load Course Simulations',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  beforeMount() {
    this.loadSimulationsData();
  },
};
</script>

<style lang="scss" scoped>
.html {
  overflow-y: scroll !important;
}

.card-width-sims {
  width: 20%;
}

#simulation-search {
  width: 30%;
}

@media (max-width: 2200px) {
  .card-width-sims {
    width: 25%;
  }
}

@media (max-width: 1800px) {
  .card-width-sims {
    width: 33.333333%;
  }
}

@media (max-width: 1300px) {
  .card-width-sims {
    width: 33.333333%;
  }
}

@media (max-width: 750px) {
  .card-width-sims {
    width: 100%;
  }

  #simulation-header {
    flex-direction: column;
    align-items: flex-start !important;
  }

  #simulation-search {
    width: 100%;
    margin-top: 1rem !important;
  }
}
</style>
